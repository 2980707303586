import styled from 'styled-components';

const ComparisonWrapper = styled.section`
  background-color: #ebecf9;
  color: #0f2137;
  padding: 40px 0;
  .rowCt {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
  }
  .twntyslider {
    height: 100%;
    width: 2px;
    background: #4d91fc;
    cursor: pointer;
    img {
      position: absolute;
      width: 50px;
      height: 50px;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  .lists {
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    p {
      padding: 15px 20px;
      padding-right: 0;
      text-align: center;
    }
    img {
      max-width: 90px;
    }
  }
  .comp_left_image {
  }
  .comp_right_image {
  }
`;

export default ComparisonWrapper;
