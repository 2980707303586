import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ParticlesWebComponent from '../../Hosting/ParticleWeb';
import Testimonialwrapper from './TestimonialWrapper';
import sampleimage from 'common/src/assets/image/hosting/testimonial/testimonial.jpeg';
import scatch from 'common/src/assets/image/hosting/testimonial/salescatcher.png';
import './test.css';
const TestimonialSection = ({
  sectionWrapper,
  maindiv,
  title,
  designation,
  review,
  row,
  title1,
  rotator,
  quotes,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Testimonialwrapper>
          <ParticlesWebComponent />
          <Box {...row}>
            <Box {...title1}>Client Speak</Box>
          </Box>
          <Box {...row}>
            <Box {...rotator}>
              <figure class="snip1192">
                <blockquote>
                  <Box {...quotes}>
                    We managed to add the capability to send highly personalised
                    responses and improve winnability of deals with RAPFlow and
                    RAPBot. This helped add significant value to our solution
                    and enhanced our competitiveness in the marketplace.
                  </Box>
                </blockquote>
                <div class="author">
                  <img
                    src={sampleimage}
                    alt="Augustin Gohil, President, SalesCatcher"
                    className="snip1192image"
                  />
                  <h5>
                    Augustin Gohil <span> President, SalesCatcher</span>
                  </h5>
                  <img src={scatch} alt="Sales Catcher's logo" />
                </div>
              </figure>
            </Box>
          </Box>
        </Testimonialwrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  rotator: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  imageArea: PropTypes.object,
  maindiv: PropTypes.object,
  designation: PropTypes.object,
  review: PropTypes.object,
  title1: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'info_home',
    pt: '15px',
    pb: '1px',
    background: '#E1BDDC',
    borderBottom: '2px solid #CECECE',
  },
  secTitleWrapper: {
    mb: ['50px', '60px', '60px', '75px'],
  },
  row: {
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ml: '-15px',
    mr: '-15px',
    width: '100%',
  },

  col: {
    pr: '15px',
    pl: '15px',
  },
  col1: {
    width: ['100%', '100%', '30%', '30%', '30%'],
  },
  col2: {
    width: ['100%', '100%', '70%', '70%', '70%'],
  },
  title1: {
    as: 'h1',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mt: 0,
    // mb: ["10px", "10px", "10px", "10px", "10px"],
    mb: '5px',
    textAlign: 'center',
  },

  imageArea: {
    width: ['100%', '100%', '50%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  maindiv: {
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '300px',
  },
  title: {
    fontSize: ['20px', '20px', '22px', '25px', '25px'],
    fontWeight: '600',
    // color: "#0f2137",
    color: '#000000',
  },
  designation: {
    fontSize: ['16px', '18px', '20px', '22px', '22px'],
    fontWeight: '600',
    // color: "#0f2137",
    color: '#7F7F7F',
  },
  review: {
    fontSize: ['14px', '16px', '18px', '18px', '18px'],
    fontWeight: '300',
    color: '#0f2137',
    textAlign: 'center',
  },
  rotator: {
    width: ['100%', '100%', '100%', '100%', '100%'],
  },
  quotes: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
  },
};

export default TestimonialSection;
// const Data = useStaticQuery(graphql`
// query {
//   hostingJson {
//     TESTIMONIALS {
//       name
//       designation
//       review
//       avatar {
//         childImageSharp {
//           fluid(quality: 100) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
// }
// `);
