import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
// import Text from 'common/src/components/Text';
// import Heading from 'common/src/components/Heading';
// import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';

// import { useStaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';

// import sample from 'common/src/assets/image/hosting/author-1.jpg';

// import Save50Img from 'common/src/assets/image/hosting/Icons/Applications/Save About 50 of Your Cost.png';
// import IncredibleScalableImg from 'common/src/assets/image/hosting/Icons/Applications/Save About 50 of Your Cost.png';
// import ZeroInterventionImg from 'common/src/assets/image/hosting/Icons/Applications/Save About 50 of Your Cost.png';
// import AccelerateProcessImg from 'common/src/assets/image/hosting/Icons/Applications/Save About 50 of Your Cost.png';
// import ErrorFreeImg from 'common/src/assets/image/hosting/Icons/Applications/Save About 50 of Your Cost.png';

import DeployImg from 'common/src/assets/image/hosting/Icons/Home/Deploy.png';
import DevelopImg from 'common/src/assets/image/hosting/Icons/Home/Develop.png';
import IdentifyImg from 'common/src/assets/image/hosting/Icons/Home/Identify.png';
import MonitorImg from 'common/src/assets/image/hosting/Icons/Home/Monitor.png';

import './iconfloating.css';

const IconFloatingsection = ({
  sectionWrapper,
  row,
  col,
  col20,
  col25,
  col30,
  col50,
  col70,
  iconHead,
  iconsubHead,
  iconDescription,
  textArea,
}) => {
  return (
    <>
      <Box
        {...sectionWrapper}
        className="Icon_Floating_Container Home MaxProEff"
      >
        <Fade bottom cascade delay={50}>
          <Box {...row} className="margin_auto flexjustifyaround">
            <Box {...col25} className="icontext_card icontext_card_whitebg">
              <Box className="flexaligncenter">
                <ul>
                  <li>
                    <Image
                      src={IdentifyImg}
                      alt="Identify"
                      className="icon_image"
                    />
                  </li>
                  <li {...iconHead}>Identify</li>
                  {/* <li {...iconsubHead}>From days to minutes</li> */}
                  <li {...iconDescription}>
                    Identify best candidates for automation
                  </li>
                </ul>
              </Box>
            </Box>
            <Box {...col25} className="icontext_card icontext_card_whitebg">
              <Box className="flexaligncenter">
                <ul>
                  <li {...iconHead}>
                    <Image
                      src={DevelopImg}
                      alt="Develop"
                      className="icon_image"
                    />
                  </li>
                  <li {...iconHead}>Develop</li>
                  {/* <li {...iconsubHead}>Unlimit Capacity</li> */}
                  <li {...iconDescription}>
                    Develop bots that are independent or collaborative
                  </li>
                </ul>
              </Box>
            </Box>
            <Box {...col25} className="icontext_card icontext_card_whitebg">
              <Box className="flexaligncenter">
                <ul>
                  <li>
                    <Image
                      src={DeployImg}
                      alt="Deploy"
                      className="icon_image"
                    />
                  </li>
                  <li {...iconHead}>Deploy</li>
                  {/* <li {...iconsubHead}>30% – 70% Reduction</li> */}
                  <li {...iconDescription}>
                    Deploy on any platform either Windows, Linux or Mac
                  </li>
                </ul>
              </Box>
            </Box>
            <Box {...col25} className="icontext_card icontext_card_whitebg">
              <Box className="flexaligncenter">
                <ul>
                  <li>
                    <Image
                      src={MonitorImg}
                      alt="Monitor"
                      className="icon_image"
                    />
                  </li>
                  <li {...iconHead}>Monitor</li>
                  {/* <li {...iconsubHead}>Reduction in errors</li> */}
                  <li {...iconDescription}>
                    Monitor and track bot performance
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
    </>
  );
};

IconFloatingsection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col20: PropTypes.object,
  col25: PropTypes.object,
  col30: PropTypes.object,
  col50: PropTypes.object,
  col70: PropTypes.object,
  iconHead: PropTypes.object,
  iconsubHead: PropTypes.object,
  iconDescription: PropTypes.object,
  textArea: PropTypes.object,
};

IconFloatingsection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    id: 'IconFloating_section',
    className: 'IconFloating_section',
    pt: ['15px', '15px', '15px', '15px'],
    background: 'transparent',
    // pb: ['60px', '80px', '80px', '100px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    className: 'col',
    width: [1, 0.45, 0.45, 0.45],
    pr: '15px',
    pl: '15px',
    mb: '20px',
  },
  col20: {
    className: 'col20',
    width: [0.4, 0.15, 0.15, 0.2],
  },
  col25: {
    className: 'col25',
    width: [1, 0.48, 0.48, 0.48],
    mr: '1%',
    ml: '1%',
    padding: '15px !important',
  },
  col30: {
    className: 'col30',
    width: [1, 1, 0.3, 0.3],
  },
  col50: {
    className: 'col50',
    width: [1, 0.45, 0.45, 0.45],
  },
  col70: {
    className: 'col70',
    width: [1, 1, 0.7, 0.7],
  },
  iconHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconHead',
  },
  iconsubHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconsubHead',
  },
  textArea: {
    width: ['100%', '85%', '85%', '85%', '80%'],
    margin: '0 auto',
  },
  iconDescription: {
    className: 'iconDescription',
    fontSize: ['18px', '18px', '20px', '20px'],
  },
};

export default IconFloatingsection;
