import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { Link } from 'gatsby';

import LinearWrapper from './linear.style';

import AnalyseImg from 'common/src/assets/image/hosting/Cycle/Home/Analyse.png';
import CreateImg from 'common/src/assets/image/hosting/Cycle/Home/Create.png';
import DeployImg from 'common/src/assets/image/hosting/Cycle/Home/Deploy.png';
import ImproveImg from 'common/src/assets/image/hosting/Cycle/Home/Improve.png';
import MonitorImg from 'common/src/assets/image/hosting/Cycle/Home/Monitor.png';
import TrainImg from 'common/src/assets/image/hosting/Cycle/Home/Train.png';

const LinearSection = ({
  sectionWrapper,
  row,
  col,
  title,
  subtitle,
  titleCircle,
  description,
  descriptionCircle,
  button,
  textArea,
  imageArea,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Heading {...subtitle} content="RAPFlow" />
        <Heading {...title} content="Full Lifecycle AI Orchestration" />
        <Text
          {...description}
          content="Build an AI solution in days or weeks not months with our next-gen content intelligence platform"
        />
        <LinearWrapper>
          <Box>
            <Box className="LinearImg">
              <img src={CreateImg} alt="Create" />
            </Box>
            <Box {...textArea}>
              <Heading {...titleCircle} content="Create" />
              <Text
                {...descriptionCircle}
                content="Create or import datasets for training"
              />
            </Box>
          </Box>
          <Box>
            <Box className="LinearImg">
              <img src={AnalyseImg} alt="Analyze" />
            </Box>
            <Box {...textArea}>
              <Heading {...titleCircle} content="Analyze" />
              <Text {...descriptionCircle} content="Analyze & augment data" />
            </Box>
          </Box>
          <Box>
            <Box className="LinearImg">
              <img src={TrainImg} alt="Train" />
            </Box>
            <Box {...textArea}>
              <Heading {...titleCircle} content="Train" />
              <Text
                {...descriptionCircle}
                content="Pre-trained or automated training"
              />
            </Box>
          </Box>
        </LinearWrapper>
        <LinearWrapper>
          <Box>
            <Box className="LinearImg">
              <img src={DeployImg} alt="Deploy" />
            </Box>
            <Box {...textArea}>
              <Heading {...titleCircle} content="Deploy" />
              <Text
                {...descriptionCircle}
                content="Deploy on-prem, or private/public cloud"
              />
            </Box>
          </Box>
          <Box>
            <Box className="LinearImg">
              <img src={MonitorImg} alt="Monitor" />
            </Box>
            <Box {...textArea}>
              <Heading {...titleCircle} content="Monitor" />
              <Text
                {...descriptionCircle}
                content="Monitor model performance"
              />
            </Box>
          </Box>
          <Box>
            <Box className="LinearImg">
              <img src={ImproveImg} alt="Improve" />
            </Box>
            <Box {...textArea}>
              <Heading {...titleCircle} content="Improve" />
              <Text
                {...descriptionCircle}
                content="Collect data, and make improvements"
              />
            </Box>
          </Box>
        </LinearWrapper>
        <Box className="btn learnMore text_center" {...button}>
          <Link to="/platform/rapflow/">
            <span className="learnMore_txt">Learn More </span>
            <i className="fa fa-long-arrow-right" />
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

LinearSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  subtitle: PropTypes.object,
  titleCircle: PropTypes.object,
  description: PropTypes.object,
  descriptionCircle: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

LinearSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['30px', '30px', '30px', '30px'],
    pb: ['30px', '30px', '30px', '30px'],
    className: 'linear_section',
    backgroundColor: '#191c3c',
    // backgroundColor: '#e6e7e9',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['90%', '50%', '50%', '60%', '60%'],
    margin: '0 auto',
    className: 'LinearData',
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    as: 'h1',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    // color: '#0f2137',
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: 0,
    mb: ['10px', '10px', '20px', '20px', '20px'],
    textAlign: 'center',
  },
  subtitle: {
    as: 'h2',
    fontSize: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '600',
    // color: '#0f2137',
    color: '#fff',
    mt: ['10px', '15px', '20px', '20px', '20px'],
    mb: ['10px', '15px', '10px', '10px', '10px'],
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#fff',
    // color: '#252525',
    lineHeight: '1.5',
    mt: ['10px', '15px', '15px', '20px', '20px'],
    mb: ['10px', '15px', '15px', '20px', '20px'],
    textAlign: 'center',
  },
  titleCircle: {
    as: 'h3',
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: '600',
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: '25px',
    mb: '5px',
    textAlign: 'center',
  },
  descriptionCircle: {
    fontSize: '12px',
    color: '#fff',
    lineHeight: '1.2',
    mt: '0px',
    mb: '10px',
    textAlign: 'center',
  },
  button: {
    mt: ['20px', '20px', '20px', '40px', '40px'],
    // mt: ['40px', '40px', '40px', '100px', '100px'],
  },
};

export default LinearSection;
