import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
// import Container from 'common/src/components/UI/Container';
// import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ComparisonWrapper from './comparison.style';
import TwentyTwenty from 'react-twentytwenty';
// import Image from 'common/src/components/Image';

// import Slider from 'common/src/components/A_imageSlider/Slider';
// import sampleimg from 'common/src/assets/image/hosting/author-1.jpg';
import cirlceimg from 'common/src/assets/image/hosting/circle_slider.png';
import sample2img from 'common/src/assets/image/hosting/manualworker.svg';
import sample3img from 'common/src/assets/image/hosting/aiwork.svg';

import imagel1 from 'common/src/assets/image/hosting/Comparison/manual/1.svg';
import imagel2 from 'common/src/assets/image/hosting/Comparison/manual/2.svg';
import imagel3 from 'common/src/assets/image/hosting/Comparison/manual/3.svg';
import imagel4 from 'common/src/assets/image/hosting/Comparison/manual/4.svg';
import imagel5 from 'common/src/assets/image/hosting/Comparison/manual/5.svg';
import imager1 from 'common/src/assets/image/hosting/Comparison/ai/1.svg';
import imager2 from 'common/src/assets/image/hosting/Comparison/ai/2.svg';
import imager3 from 'common/src/assets/image/hosting/Comparison/ai/3.svg';
import imager4 from 'common/src/assets/image/hosting/Comparison/ai/4.svg';
import imager5 from 'common/src/assets/image/hosting/Comparison/ai/5.svg';

export default function ComparisonSection({
  row,
  col2,
  col8,
  mainheading,
  mainheadingholder,
  listcontent,
  listtext,
  headingrow,
  headingtext,
  subheadingtext,
  paragraphtext,
}) {
  const leftcontents = [
    {
      image: imagel1,
      text: 'Slow and inefficient process',
    },
    {
      image: imagel2,
      text: 'Inability to scale on demand',
    },
    {
      image: imagel3,
      text: 'Very high operating costs',
    },
    {
      image: imagel4,
      text: 'Error-prone / data inconsistencies',
    },
    {
      image: imagel5,
      text: 'Time spent on mundane tasks',
    },
  ];
  const rightcontents = [
    {
      image: imager1,
      text: 'Faster process with higher efficiency',
    },
    {
      image: imager2,
      text: 'Highly scalable process',
    },
    {
      image: imager3,
      text: 'Reduction in opex by 30-70%',
    },
    {
      image: imager4,
      text: 'Guaranteed predictable outcomes',
    },
    {
      image: imager5,
      text: 'Time available for value added tasks',
    },
  ];

  return (
    <ComparisonWrapper className="image-comparison_section_slider">
      <Box {...headingrow}>
        <Fade bottom cascade>
          {/* <Heading {...headingtext} content="Jump to Lightspeed" /> */}
          <Heading
            {...headingtext}
            content="Rev Up Process Efficiency & Break Traditional Manual Barriers"
          />
          <Heading
            {...subheadingtext}
            content="Transform your business and accelerate process efficiency with cutting-edge, disruptive, AI-powered content intelligence and workflow automation."
          />
        </Fade>
      </Box>
      <Box {...row}>
        <Box {...col2}>
          <Fade left cascade>
            <Box {...mainheadingholder}>
              <Heading {...mainheading} content="Manual" />
              <Heading {...mainheading} content="Processes" />
            </Box>
            {/* <ul className="lists left">
              <li>
                <img src={sampleimg} alt="" />
                <p>Decision-making based on simplistic analysis and opinions</p>
              </li>
            </ul> */}
            {leftcontents.map((x, i) => (
              <Box {...listcontent}>
                <img
                  src={x.image}
                  style={{
                    minWidth: '75px',
                    maxWidth: '75px',
                    borderRadius: '50%',
                    padding: '10px',
                    border: '2px solid #060633',
                  }}
                  className="comp_left_image"
                  alt={x.text}
                ></img>
                <Text {...listtext} content={x.text} />
              </Box>
            ))}
          </Fade>
        </Box>
        <Box {...col8}>
          <TwentyTwenty
            left={<img src={sample3img} alt="Rapai" />}
            right={<img src={sample2img} alt="manual work" />}
            slider={
              <div className="twntyslider">
                <img src={cirlceimg} alt="circle" />
              </div>
            }
          />
          {/* <Slider
            image1={sample3img}
            image2={sample2img}
            sliderimage={cirlceimg}
          /> */}
        </Box>
        <Box {...col2}>
          <Fade right cascade>
            <Box {...mainheadingholder}>
              <Heading {...mainheading} content="Intelligent" />
              <Heading {...mainheading} content="Automation" />
            </Box>
            {/* <ul className="lists right">
              <li>
                <img src={sampleimg} alt="" />
                <p>Decision-making based on simplistic analysis and opinions</p>
              </li>
            </ul> */}
            {rightcontents.map((x, i) => (
              <Box {...listcontent}>
                <img
                  src={x.image}
                  style={{
                    minWidth: '75px',
                    maxWidth: '75px',
                    borderRadius: '50%',
                    padding: '10px',
                    border: '2px solid #060633',
                    color: 'blue',
                  }}
                  className="comp_right_image"
                  alt={x.text}
                ></img>
                <Text {...listtext} content={x.text} />
              </Box>
            ))}
          </Fade>
        </Box>
      </Box>
    </ComparisonWrapper>
  );
}

ComparisonSection.propTypes = {
  row: PropTypes.object,
  col2: PropTypes.object,
  col8: PropTypes.object,
  mainheading: PropTypes.object,
};

ComparisonSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'start',
    pl: '5px',
    pr: '5px',
    className: 'rowCt',
  },
  headingrow: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pl: '5px',
    pr: '5px',
    className: 'rowCt',
  },
  col2: {
    width: ['100%', '100%', '20%', '20%', '20%'],
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  col8: {
    width: ['100%', '100%', '60%', '60%', '60%'],
    pl: ['10px', '10px', '50px', '50px', '50px'],
    pr: ['10px', '10px', '50px', '50px', '50px'],
    mt: '50px',
    alignSelf: 'center',
  },
  mainheadingholder: {
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: ['10px', '15px', '20px', '20px', '20px'],
    mb: ['10px', '15px', '10px', '10px', '10px'],
  },
  mainheading: {
    as: 'h2',
    fontSize: ['24px', '24px', '24px', '24px', '24px'],
    fontWeight: '600',
    color: '#0F2137',
    textAlign: 'center',
    mb: '-0.4rem',
  },
  listcontent: {
    flexBox: true,
    alignItems: 'center',
    mt: '20px',
    flexDirection: ['column', 'column', 'row', 'row', 'row'],
  },
  listtext: {
    pl: '10px',
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: '400',
    color: 'black',
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    mt: ['10px', '10px', '0px', '0px', '0px'],
  },
  headingtext: {
    fontSize: ['20px', '20px', '25px', '30px', '30px'],
    textAlign: 'center',
  },
  subheadingtext: {
    fontSize: ['18px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  paragraphtext: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    textAlign: 'center',
  },
};
