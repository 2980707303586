import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import Whatifwrapper from './Whatifwrapper';
import { Link } from 'gatsby';
import Whatifhover from './Whatifhover';

import scaleimage from 'common/src/assets/image/hosting/Icons/Home/scaleupvector.webp';
import errorfreeimage from 'common/src/assets/image/hosting/Icons/Home/Errorfreevectors.webp';
import operatingcostimage from 'common/src/assets/image/hosting/Icons/Home/Operatingcostsvector.webp';
import reducetime from 'common/src/assets/image/hosting/Icons/Home/reduce80.svg';

const InfoHomeSection = ({
  sectionWrapper,
  row,
  col,
  col1,
  col2,
  title,
  title1,
  subtitle,
  subtitle1,
  description,
  button,
  textArea,
  contentArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  secTitleWrapper,
  secHeading,
  secText,
  box_size,
  maincontent,
  subcontent,
  titleCircle,
  descriptionCircle,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Fade bottom cascade>
          <div className="empty_space20" />
          <Box {...textArea}>
            <Heading {...title1} content="What if" className="text_center" />
          </Box>
          <Box {...maincontent}>
            <Whatifhover>
              <Box>
                <Box className="LinearImg">
                  <img src={scaleimage} alt="scaleimage" />
                </Box>
                <Box {...textArea}>
                  {/* <Heading {...titleCircle} content="Deploy" /> */}
                  <Text
                    {...descriptionCircle}
                    content="You have unlimited capacity to scale at will?"
                  />
                </Box>
              </Box>
              <Box>
                <Box className="LinearImg">
                  <img
                    src={errorfreeimage}
                    alt="error free and efficient image"
                  />
                </Box>
                <Box {...textArea}>
                  {/* <Heading {...titleCircle} content="Monitor" /> */}
                  <Text
                    {...descriptionCircle}
                    content="Your processes are error-free and efficient?"
                  />
                </Box>
              </Box>
              <Box>
                <Box className="LinearImg">
                  <img
                    src={operatingcostimage}
                    alt="less operating cost image"
                  />
                </Box>
                <Box {...textArea}>
                  {/* <Heading {...titleCircle} content="Improve" /> */}
                  <Text
                    {...descriptionCircle}
                    content=" You could reduce at least 50% of operating costs?"
                  />
                </Box>
              </Box>
              <Box>
                <Box className="LinearImg">
                  <img src={reducetime} alt="reduce operation time" />
                </Box>
                <Box {...textArea}>
                  {/* <Heading {...titleCircle} content="Improve" /> */}
                  <Text
                    {...descriptionCircle}
                    content="Reduce turn-around time by 80%"
                  />
                </Box>
              </Box>
            </Whatifhover>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

InfoHomeSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  title1: PropTypes.object,
  subtitle: PropTypes.object,
  subtitle1: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  box_size: PropTypes.object,
  titleCircle: PropTypes.object,
  description: PropTypes.object,
  descriptionCircle: PropTypes.object,
};

InfoHomeSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'info_home',
    pt: '10px',
    pb: '30px',
    backgroundColor: '#191C3C',
  },
  secTitleWrapper: {
    mb: ['50px', '60px', '60px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#eb4d4b',
    mb: '10px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  col1: {
    width: ['100%', '100%', '30%', '30%', '30%'],
  },
  col2: {
    width: ['100%', '100%', '70%', '70%', '70%'],
  },
  textArea: {
    width: ['95%', '95%', '85%', '85%', '85%'],
    margin: '0 auto',
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  imageArea: {
    width: ['100%', '100%', '50%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    ml: '-200px',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px',
  },
  title: {
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mt: ['10px', '15px', '20px', '20px', '20px'],
    mb: ['10px', '15px', '10px', '10px', '10px'],
  },
  subtitle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '600',
    color: '#0f2137',
    mt: 0,
    mb: ['10px', '10px', '20px', '20px', '20px'],
  },
  title1: {
    as: 'h1',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    // color: "#0f2137",
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: 0,
    mb: ['10px', '10px', '20px', '20px', '20px'],
    textAlign: 'center',
  },
  subtitle1: {
    as: 'h2',
    fontSize: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '600',
    color: '#0f2137',
    mt: ['10px', '15px', '20px', '20px', '20px'],
    mb: ['10px', '15px', '10px', '10px', '10px'],
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: ['10px', '15px', '15px', '20px', '20px'],
    mb: ['10px', '15px', '15px', '20px', '20px'],
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  box_size: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  maincontent: {
    flexBox: true,
    flexWrap: 'wrap',
    width: '100%',
    mt: '10px',
    justifyContent: 'center',
  },
  subcontent: {
    width: ['100%', '100%', '50%', '50%', '50%'],
  },
  titleCircle: {
    as: 'h3',
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    fontWeight: '600',
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: '25px',
    mb: '5px',
    textAlign: 'center',
  },
  descriptionCircle: {
    fontSize: '16px',
    color: '#fff',
    lineHeight: '1.2',
    mt: '25px',
    mb: '10px',
    textAlign: 'center',
  },
};

export default InfoHomeSection;
