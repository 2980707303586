import styled from 'styled-components';

const SuccessStoryWrapper = styled.div`
  .success_image {
    max-width: 100%;
    max-height: 100%;
  }
`;

export default SuccessStoryWrapper;
