import styled from 'styled-components';

const Whatifwrapper = styled.div`
  width: 100%;
  .gridholder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media only screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr ifr;
    }
    .subgrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      align-items: center;
    }
  }
  .gridbox {
    min-height: 250px;
    padding: 10px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gridbox1 {
    background-color: #dbc9bb;
  }
  .gridbox2 {
    background-color: #f46f60;
  }
  .gridbox3 {
    background-color: #f0ece3;
  }
  .gridbox4 {
    background-color: #bed441;
  }
  .gridbox5 {
    background-color: #fcdc29;
  }
  .gridbox6 {
    background-color: #dbc9bb;
  }
  .gridbox7 {
    background-color: #17969c;
  }
  .gridbox8 {
    background-color: #dbc9bb;
  }
`;
export default Whatifwrapper;
