import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import GliderSlider1Wrapper from './glideslider.style';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'common/src/components/Image';

const GlideSlider = ({ secTitleWrapper, secText, secHeading }) => {
  const Data = useStaticQuery(graphql`
    query {
      hostingJson {
        PARTNERS {
          alt
          avatar {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const glide1option = {
    type: 'carousel',
    autoplay: 1000,
    perView: 5,
    gap: 0,
    animationDuration: 3000,
    animationTimingFunc: 'linear',
    hoverpause: true,
    peek: {
      before: 100,
      after: 100,
    },
    breakpoints: {
      800: {
        perView: 3,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        perView: 2,
        peek: {
          before: 30,
          after: 30,
        },
      },
    },
  };
  return (
    <>
      <GliderSlider1Wrapper className="section_background_color">
        <Container fullWidth noGutter>
          <Box {...secTitleWrapper} className="ourpartnerheading">
            <Heading {...secHeading} content="Our Happy Customers" />
          </Box>
          <GlideCarousel
            options={glide1option}
            nextButton={
              <Button
                icon={<i className="flaticon-next" />}
                variant="textButton"
                aria-label="next button"
              />
            }
            prevButton={
              <Button
                icon={<i className="flaticon-left-arrow" />}
                variant="textButton"
                aria-label="prev button"
              />
            }
          >
            <>
              {Data.hostingJson.PARTNERS.map((slideItem, index) => (
                <GlideSlide key={`customer-slide-${index}`}>
                  <Image
                    src={slideItem.avatar.childImageSharp.fluid.src}
                    alt={slideItem.alt}
                  />
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </Container>
      </GliderSlider1Wrapper>
    </>
  );
};

GlideSlider.propTypes = {
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
};
GlideSlider.defaultProps = {
  secTitleWrapper: {
    as: 'section',
    // pt: '30px',
    // pb: '30px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['30px', '30px', '35px', '40px', '45px'],
    // color: '#06324e',
    pb: '25px',
    mt: '25px',
  },
  secHeading: {
    as: 'h2',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    pt: '35px ',
    mb: '25px',
    textAlign: 'center',
  },
};
export default GlideSlider;
