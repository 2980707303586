import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ParticlesWebComponent from '../../Hosting/ParticleWeb';
import SuccessStoryWrapper from './SuccessStoryWrapper';
import image1 from 'common/src/assets/image/hosting/Pages/CaseStudy/Case-Study-4.webp';
import image2 from 'common/src/assets/image/hosting/Pages/CaseStudy/Case-Study-5.webp';

const TestimonialSection = ({
  sectionWrapper,
  maindiv,
  subtitle,
  successhead,
  description,
  review,
  row,
  title1,
  rotator,
  coll,
  colr,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Box {...sectionWrapper}>
      <Container>
        <SuccessStoryWrapper>
          <Box>
            <Container>
              <Box {...row}></Box>
              <Fade bottom cascade>
                <Carousel
                  arrows={false}
                  swipeable={true}
                  draggable={false}
                  showDots={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={10000}
                  keyBoardControl={false}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {/* Content 1 */}
                  <Box {...maindiv}>
                    <Box {...coll}>
                      <Text {...successhead} content="Success stories" />
                      <Text
                        {...subtitle}
                        content="90% reduction in quote processing time"
                      />
                      <Text
                        {...description}
                        content="A major aircraft parts dealer automated quote processing using AI to reduce turnaround time and improved process efficiency."
                      />
                      <Box className="home_button">
                        <Link
                          to="/case-study/Accelerate-Quote-Processing-with-AI-Automation"
                          className="btn btn_tertiary2"
                        >
                          <span className="btn-text">Download Case Study</span>
                        </Link>
                      </Box>
                    </Box>
                    <Box {...colr}>
                      <div className="success_imageholder">
                        <img
                          src={image1}
                          alt="quote processing technology of Rapid acceleration partners"
                          className="success_image"
                        />
                      </div>
                    </Box>
                  </Box>
                  {/* content 2 */}
                  <Box {...maindiv}>
                    <Box {...coll}>
                      <Text {...successhead} content="Success stories" />
                      <Text
                        {...subtitle}
                        content=" Increased conversions through fast quote response"
                      />
                      <Text
                        {...description}
                        content="A major US-based auto dealer improved conversions by reducing customer quote response with intelligent automation."
                      />
                      <Box className="home_button">
                        <Link
                          to="/case-study/Increasing-Winnability-of-Automobile-Sales-with-AI"
                          className="btn btn_tertiary2"
                        >
                          <span className="btn-text">Download Case Study</span>
                        </Link>
                      </Box>
                    </Box>
                    <Box {...colr}>
                      <div className="success_imageholder">
                        <img
                          src={image2}
                          alt="incerease sales of automobile with Rapid acceleration partners"
                          className="success_image"
                        />
                      </div>
                    </Box>
                  </Box>
                </Carousel>
              </Fade>
            </Container>
          </Box>
        </SuccessStoryWrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  rotator: PropTypes.object,
  col1: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
  imageArea: PropTypes.object,
  maindiv: PropTypes.object,
  designation: PropTypes.object,
  review: PropTypes.object,
  title1: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'successstories',
    pt: '50px',
    pb: '50px',
    backgroundColor: '#191c3c',
    borderBottom: '2px solid #CECECE',
  },
  secTitleWrapper: {
    mb: ['50px', '60px', '60px', '75px'],
  },
  row: {
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ml: '-15px',
    mr: '-15px',
    width: '100%',
  },

  coll: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
    pt: '50px',
    order: ['2', '2', '0', '0', '0'],
  },
  colr: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    order: ['1', '1', '0', '0', '0'],
  },
  col1: {
    width: ['100%', '100%', '30%', '30%', '30%'],
  },
  col2: {
    width: ['100%', '100%', '70%', '70%', '70%'],
  },
  title1: {
    as: 'h1',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mt: 0,
    mb: ['10px', '10px', '30px', '50px', '50px'],
    textAlign: 'center',
  },

  imageArea: {
    width: ['100%', '100%', '50%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  maindiv: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    // alignItems: "start",
    width: '100%',
    minHeight: '300px',
    pb: '30px',
  },
  successhead: {
    fontSize: ['14px', '16px', '18px', '18px', '18px'],
    fontWeight: '600',
    // color: "#0f2137",
    color: '#3DB9C3',
  },
  subtitle: {
    fontSize: ['16px', '18px', '20px', '22px', '22px'],
    fontWeight: '600',
    // color: "#0f2137",
    color: '#fff',
  },
  description: {
    fontSize: ['14px', '16px', '18px', '18px', '18px'],
    fontWeight: '300',
    color: '#7F7F7F',
    textAlign: 'left',
  },
};

export default TestimonialSection;
