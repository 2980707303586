import styled from 'styled-components';
import HomeBG from 'common/src/assets/image/hosting/Banner/home.webp';
import BannerLeftImg from 'common/src/assets/image/hosting/Background/Middle banner left.svg';
import BannerRightImg from 'common/src/assets/image/hosting/Background/Middle banner right.svg';

const BannerWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-center: center;
  overflow:hidden;
  .main_home_banner_image_parent{
    position:relative
  }
  .advertisement_image{
    max-width:100%;
    max-height:100%;
  }
  .main_home_banner_image{
    max-width:100%;
      // -webkit-transform:scalex(1.3) scaley(1);
      // -moz-transform:scalex(1.3) scaley(1);
      // -ms-transform:scalex(1.3) scaley(1);
      // -o-transform:scalex(1.3) scaley(1);
      // transform:scalex(1.3) scaley(1);
    position:relative;
    top:50px;
    // left:-50px;
    z-index:-5;
    @media (max-width: 1000px) {
      // -webkit-transform:scale(1);
      // -moz-transform:scale(1);
      //  -ms-transform:scale(1);
      //   -o-transform:scale(1);
      //      transform:scale(1);
   
      top:unset;
      left:-unset;
    }
  }
  .particle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &.banner_container {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: none;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      // background-image: linear-gradient(
      //   180deg,
      //   #081275 15%,
      //   rgba(8, 18, 117, 0.75)
      // );
      opacity: 0.85;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &.banner_bg::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.85;
      width: 100%;
      height: 100%;
      background-image: url(${BannerLeftImg});
      background-size: cover;
      background-position: center left;
      background-repeat: no-repeat;
      z-index: 1;
      opacity: 0.1;
      background-blend-mode: color;
    }
    &.banner_bg::after {
      content: "";
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        #081275 15%,
        rgba(8, 18, 117, 0.75)
      );
      opacity: 0.85;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &.banner_bg .container::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.85;
      width: 100%;
      height: 100%;
      background-image: url(${BannerRightImg});
      background-size: cover;
      background-position: center right;
      background-repeat: no-repeat;
      z-index: 1;
      opacity: 0.1;
      background-blend-mode: color;
    }
  }
  .HomePage &.Home {
    display: flex;
    // background-image: url(${HomeBG});
    padding-top: 100px;
    padding-bottom: 0px;
  }
  .seperator_line {
    background-color: white;
    width: 15%;
    height: 4px;
    max-width: 120px;
  }
  .container {
    z-index: 9;
  }
  .home_button {
    // position: relative;
    // left: 50%;
    bottom: 10px;
    // transform: translate(-50%, -10%);
    z-index: 10;
  }
  .home_box {
    position: relative;
    display: block;
    margin: 0 0;
    flex: 1;
    z-index: 4;
  }
  .no-cursor {
    cursor: default;
    user-select: none;
  }
  @media only screen and (min-width: 1280px) and (min-height: 550px) {
    .HomePage &.Home {
      padding-top: 50px;
      padding-bottom: 0px;
      height: calc(80vh - 36px);
      max-height: 575px;
    }
  }
  @media only screen and (min-width: 1440px) and (min-height: 550px) {
    .home_button {
      bottom: -10px;
    }
  }
`;

export default BannerWrapper;
