import styled from 'styled-components';

const Snakewrapper = styled.div`
  .container .card:hover {
    .subtitle,
    .title,
    .description {
      color: #fff;
    }
    .platfromimage {
      filter: brightness(0) invert(1);
    }
  }
  .platfromimage {
    // height:100px;
    // width:100px;
    width: 100%;
  }
  .container .card {
    position: relative;
    // background: #0c002b;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  .container .card:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-image: linear-gradient(to right, #f25663, #f25663 50%, #f25a89);
    opacity: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.12, 0.845, 0.305, 1);
    -moz-transition: all 0.5s cubic-bezier(0.12, 0.845, 0.305, 1);
    -ms-transition: all 0.5s cubic-bezier(0.12, 0.845, 0.305, 1);
    -o-transition: all 0.5s cubic-bezier(0.12, 0.845, 0.305, 1);
    transition: all 0.5s cubic-bezier(0.12, 0.845, 0.305, 1);
  }

  .container .card:hover:before {
    opacity: 1;
  }

  .runner {
    transition: 0.5;
    opacity: 0;
  }
  .container .card:hover runner {
    opacity: 0;
  }
  .runner1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    // background: linear-gradient(to right, transparent, #1779ff);
    background: linear-gradient(
      to right,
      transparent,
      #f25663,
      #f25663 50%,
      #f25a89
    );
    animation: animate1 2s linear infinite;
  }
  @keyframes animate1 {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  .runner2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3px;
    // background: linear-gradient(to bottom, transparent, #1779ff);
    background: linear-gradient(
      to bottom,
      transparent,
      #f25663,
      #f25663 50%,
      #f25a89
    );
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
  }
  @keyframes animate2 {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }
  .runner3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    // background: linear-gradient(to left, transparent, #1779ff);
    background: linear-gradient(
      to left,
      transparent,
      #f25663,
      #f25663 50%,
      #f25a89
    );
    animation: animate3 2s linear infinite;
  }
  @keyframes animate3 {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .runner4 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3px;
    // background: linear-gradient(to top, transparent, #1779ff);
    background: linear-gradient(
      to top,
      transparent,
      #f25663,
      #f25663 50%,
      #f25a89
    );
    animation: animate4 2s linear infinite;
    animation-delay: 1s;
  }
  @keyframes animate4 {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
`;
export default Snakewrapper;
