import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
// import Achievementimage from 'common/src/assets/image/hosting/news/achievement.png';

import CountUp from 'react-countup';
// import CountUp, { useCountUp } from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import './stats2.css';
import { Link } from 'gatsby';

const StatsSectionLight = ({
  sectionWrapper,
  row,
  col21p,
  col18p,
  col15p,
  secTitleWrapper,
  secHeading,
  secHeading2,
  secText,
  statsHead,
  statsSubHead,
  statsDesc,
  featureItemHeading,
  featureItemImage,
  featureItemDes,
  featureBlockStyle,
  iconStyle,
  contentStyle,
  props,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <>
          <Box {...row}>
            <Box {...col18p}>
              <Heading
                {...secHeading}
                content="Committed to Making IPA Work For You"
              />
            </Box>
            <Box {...col15p} className="col seperator p15">
              {/* <Link to="/news/analytics-insights-magazine" style={{height:"100%"}}>
                <img
                  src={Achievementimage}
                  alt="achievement"
                  style={{ width: "100%",height:"100%" }}
                />
              </Link> */}
              <Heading {...statsHead} content="6" />
              <Text {...statsSubHead} content="Industries" />
            </Box>
            <Box {...col21p} className="col seperator p15">
              <Heading
                {...statsHead}
                dangerouslySetInnerHTML={{
                  __html: "7 <span class='statsHeadSmall'>million+</span>",
                }}
              />
              <Text {...statsSubHead} content="Content pieces processed" />
            </Box>
            <Box {...col18p} className="col seperator p15">
              <Heading
                {...statsHead}
                dangerouslySetInnerHTML={{
                  __html: "10 <span class='statsHeadSmall'>million+</span>",
                }}
              />
              <Text {...statsSubHead} content="Process Iterations" />
            </Box>
            <Box {...col18p} className="col seperator p15">
              <Heading {...statsHead} content="70%" />
              <Text {...statsSubHead} content="Manual effort saved" />
            </Box>
          </Box>
        </>
      </Container>
    </Box>
  );
};

StatsSectionLight.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secHeading2: PropTypes.object,
  secText: PropTypes.object,
  statsHead: PropTypes.object,
  statsSubHead: PropTypes.object,
  statsDesc: PropTypes.object,
  featureItemHeading: PropTypes.object,
  featureItemImage: PropTypes.object,
  featureItemDes: PropTypes.object,
  featureBlockStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  contentStyle: PropTypes.object,
};

StatsSectionLight.defaultProps = {
  sectionWrapper: {
    as: 'section',
    id: 'stats_light_section',
    className: 'stats_light',
    pt: ['20px', '20px', '0px', '0px'],
    pt: ['20px', '20px', '0px', '0px'],
    // pb: ['60px', '80px', '80px', '100px'],
  },
  secTitleWrapper: {
    mb: ['20px', '20px', '30px', '35px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: ['18px', '18px', '18px', '18px', '18px'],
    fontWeight: '300',
    color: '#0f2137',
    mb: '25px',
    pt: '25px',
  },
  secHeading: {
    as: 'h1',
    fontSize: ['35px', '35px', '28px', '28px', '28px'],
    fontWeight: '600',
    textAlign: 'center',
    color: '#0f2137',
    mb: '0',
    pt: '0',
    className: 'stats_light_head1',
  },
  secHeading2: {
    fontSize: ['45px', '45px', '54px', '54px', '54px'],
    fontWeight: '300',
    textAlign: 'center',
    color: '#0f2137',
    mb: '25px',
    pt: '25px',
    className: 'stats_light_head2',
  },
  statsHead: {
    as: 'h4',
    fontSize: ['45px', '45px', '45px', '45px', '45px'],
    fontWeight: '600',
    textAlign: 'center',
    color: '#0f2137',
    mb: '0',
    pt: '0',
    lineHeight: '1.2',
  },
  statsSubHead: {
    fontSize: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '300',
    textAlign: 'center',
    color: '#0f2137',
    mb: '0',
    pt: '0',
    lineHeight: '1.2',
  },
  statsDesc: {
    fontSize: ['18px', '18px', '18px', '18px', '18px'],
    fontWeight: '300',
    textAlign: 'left',
    color: '#0f2137',
    mb: '0',
    pt: '10px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    pt: ['20px', '20px', '20px', '20px', '0px'],
    pb: ['20px', '20px', '20px', '20px', '0px'],
  },
  col18p: {
    width: [1, 1, 1, 1, '18%'],
    className: 'col',
    bg: '#fff',
    margin: '0 1%',
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  col15p: {
    width: [1, 1, 1, 1, '12%'],
    className: 'col',
    bg: '#fff',
    margin: '0 1%',
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  col21p: {
    width: [1, 1, 1, 1, '24%'],
    className: 'col',
    bg: '#fff',
    margin: '0 1%',
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  featureBlockStyle: {
    p: '20px 20px',
    className: 'service_item',
  },
  iconStyle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    mb: '10px',
  },
  contentStyle: {
    textAlign: 'center',
  },
  featureItemHeading: {
    fontSize: ['16px', '18px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: '20px',
    letterSpacing: '-0.020em',
  },
  featureItemImage: {
    width: '120px !important',
    height: '120px !important',
  },
  featureItemDes: {
    fontSize: '15px',
    lineHeight: '1.84',
    color: '#343d48cc',
    mb: '10px',
  },
};

export default StatsSectionLight;
