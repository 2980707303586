import styled from 'styled-components';

const Whatifhover = styled.div`
  padding-top: 25px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  > div {
    width: 25%;
    position: relative;
  }
  > div:nth-child(1),
  div:nth-child(5),
  div:nth-child(3) {
    // top: 65px;
    top: 0;
  }
  .LinearImg {
    width: 100%;
    height: 100%;
    min-height: 95px;
    object-fit: contain;
    position: relative;
    margin-bottom: 12px;
    margin: 0 auto;
    text-align: center;
  }
  img {
    width: 90px;
    height: 90px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: relative;
    transform: translateY(0px);
    transition: transform 0.3s ease-in-out;
    transition-delay: 0.1s;
    transform-style: preserve-3d;
  }
  .LinearImg:hover img {
    transform: translateY(-4px);
  }
  .LinearImg::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(8, 18, 117, 0.15);
    height: 70%;
    width: 70%;
    max-height: 110px;
    max-width: 110px;
    opacity: 1;
    // z-index: -1;
    border-radius: 50%;
    transform-style: preserve-3d;
    transform: translateX(-50%) rotate3d(14, 0, 0, 70deg);
    box-shadow: 0px 48px 26px 5px rgba(255, 255, 255, 0.3);
    transition: box-shadow 0.1s ease-in-out;
  }
  .LinearImg:hover::before {
    box-shadow: 0px 48px 26px 0px rgba(255, 255, 255, 0.35),
      inset 0px 0px 20px 5px rgba(8, 18, 117, 0.2),
      0px -1px 1px rgba(255, 255, 255, 0.02),
      0px -2px 1px rgba(255, 255, 255, 0.02),
      0px -3px 1px rgba(255, 255, 255, 0.02),
      0px -4px 1px rgba(255, 255, 255, 0.02),
      0px -5px 1px rgba(255, 255, 255, 0.02),
      0px -6px 1px rgba(255, 255, 255, 0.02),
      0px -7px 1px rgba(255, 255, 255, 0.02),
      0px -8px 1px rgba(255, 255, 255, 0.02),
      0px -9px 1px rgba(255, 255, 255, 0.02),
      0px -10px 1px rgba(255, 255, 255, 0.02),
      0px -11px 1px rgba(255, 255, 255, 0.02),
      0px -12px 1px rgba(255, 255, 255, 0.02),
      0px -13px 1px rgba(255, 255, 255, 0.02),
      0px -14px 1px rgba(255, 255, 255, 0.02),
      0px -15px 1px rgba(255, 255, 255, 0.02),
      0px -16px 1px rgba(255, 255, 255, 0.02),
      0px -17px 1px rgba(255, 255, 255, 0.02),
      0px -18px 1px rgba(255, 255, 255, 0.02),
      0px -19px 1px rgba(255, 255, 255, 0.02),
      0px -20px 1px rgba(255, 255, 255, 0.02),
      0px -21px 1px rgba(255, 255, 255, 0.02),
      0px -22px 1px rgba(255, 255, 255, 0.02),
      0px -23px 1px rgba(255, 255, 255, 0.02),
      0px -24px 1px rgba(255, 255, 255, 0.02),
      0px -25px 1px rgba(255, 255, 255, 0.02),
      0px -26px 1px rgba(255, 255, 255, 0.02),
      0px -27px 1px rgba(255, 255, 255, 0.02),
      0px -28px 1px rgba(255, 255, 255, 0.015),
      0px -29px 1px rgba(255, 255, 255, 0.015),
      0px -30px 1px rgba(255, 255, 255, 0.015),
      0px -31px 1px rgba(255, 255, 255, 0.015),
      0px -32px 1px rgba(255, 255, 255, 0.015),
      0px -33px 1px rgba(255, 255, 255, 0.015),
      0px -34px 1px rgba(255, 255, 255, 0.015),
      0px -35px 1px rgba(255, 255, 255, 0.015),
      0px -36px 1px rgba(255, 255, 255, 0.015),
      0px -37px 1px rgba(255, 255, 255, 0.015),
      0px -38px 1px rgba(255, 255, 255, 0.015),
      0px -39px 1px rgba(255, 255, 255, 0.015),
      0px -40px 1px rgba(255, 255, 255, 0.015),
      0px -41px 1px rgba(255, 255, 255, 0.015),
      0px -42px 1px rgba(255, 255, 255, 0.015),
      0px -43px 1px rgba(255, 255, 255, 0.015),
      0px -44px 1px rgba(255, 255, 255, 0.015),
      0px -45px 1px rgba(255, 255, 255, 0.015),
      0px -46px 1px rgba(255, 255, 255, 0.015),
      0px -47px 1px rgba(255, 255, 255, 0.015),
      0px -48px 1px rgba(255, 255, 255, 0.015),
      0px -49px 1px rgba(255, 255, 255, 0.015),
      0px -50px 1px rgba(255, 255, 255, 0.015),
      0px -51px 1px rgba(255, 255, 255, 0.015),
      0px -52px 1px rgba(255, 255, 255, 0.015),
      0px -53px 1px rgba(255, 255, 255, 0.015),
      0px -54px 1px rgba(255, 255, 255, 0.015),
      0px -55px 1px rgba(255, 255, 255, 0.01),
      0px -56px 1px rgba(255, 255, 255, 0.01),
      0px -57px 1px rgba(255, 255, 255, 0.01),
      0px -58px 1px rgba(255, 255, 255, 0.01),
      0px -59px 1px rgba(255, 255, 255, 0.01),
      0px -60px 1px rgba(255, 255, 255, 0.01),
      0px -61px 1px rgba(255, 255, 255, 0.01),
      0px -62px 1px rgba(255, 255, 255, 0.01),
      0px -63px 1px rgba(255, 255, 255, 0.01),
      0px -64px 1px rgba(255, 255, 255, 0.01),
      0px -65px 1px rgba(255, 255, 255, 0.01),
      0px -66px 1px rgba(255, 255, 255, 0.01),
      0px -67px 1px rgba(255, 255, 255, 0.01),
      0px -68px 1px rgba(255, 255, 255, 0.01),
      0px -69px 1px rgba(255, 255, 255, 0.01),
      0px -70px 1px rgba(255, 255, 255, 0.01),
      0px -71px 1px rgba(255, 255, 255, 0.01),
      0px -72px 1px rgba(255, 255, 255, 0.01),
      0px -73px 1px rgba(255, 255, 255, 0.01),
      0px -74px 1px rgba(255, 255, 255, 0.01),
      0px -75px 1px rgba(255, 255, 255, 0.01),
      0px -76px 1px rgba(255, 255, 255, 0.01),
      0px -77px 1px rgba(255, 255, 255, 0.01),
      0px -78px 1px rgba(255, 255, 255, 0.01),
      0px -79px 1px rgba(255, 255, 255, 0.01),
      0px -80px 1px rgba(255, 255, 255, 0.01),
      0px -81px 1px rgba(255, 255, 255, 0.01),
      0px -82px 1px rgba(255, 255, 255, 0.01),
      0px -83px 1px rgba(255, 255, 255, 0.01),
      0px -84px 1px rgba(255, 255, 255, 0.01),
      0px -85px 1px rgba(255, 255, 255, 0.01),
      0px -86px 1px rgba(255, 255, 255, 0.01),
      0px -87px 1px rgba(255, 255, 255, 0.01),
      0px -88px 1px rgba(255, 255, 255, 0.01),
      0px -89px 1px rgba(255, 255, 255, 0.01),
      0px -90px 1px rgba(255, 255, 255, 0.01),
      0px -91px 1px rgba(255, 255, 255, 0.01),
      0px -92px 1px rgba(255, 255, 255, 0.01),
      0px -93px 1px rgba(255, 255, 255, 0.01),
      0px -94px 1px rgba(255, 255, 255, 0.01),
      0px -95px 1px rgba(255, 255, 255, 0.01),
      0px -96px 1px rgba(255, 255, 255, 0.01),
      0px -97px 1px rgba(255, 255, 255, 0.01),
      0px -98px 1px rgba(255, 255, 255, 0.01),
      0px -99px 1px rgba(255, 255, 255, 0.01),
      0px -100px 1px rgba(255, 255, 255, 0.01),
      0px -101px 1px rgba(255, 255, 255, 0.01),
      0px -102px 1px rgba(255, 255, 255, 0.01),
      0px -103px 1px rgba(255, 255, 255, 0.01),
      0px -104px 1px rgba(255, 255, 255, 0.01),
      0px -105px 1px rgba(255, 255, 255, 0.01),
      0px -106px 1px rgba(255, 255, 255, 0.01),
      0px -107px 1px rgba(255, 255, 255, 0.01),
      0px -108px 1px rgba(255, 255, 255, 0.01),
      0px -109px 1px rgba(255, 255, 255, 0.01),
      0px -110px 1px rgba(255, 255, 255, 0.01),
      0px -111px 1px rgba(255, 255, 255, 0.01),
      0px -112px 1px rgba(255, 255, 255, 0.01),
      0px -113px 1px rgba(255, 255, 255, 0.01),
      0px -114px 1px rgba(255, 255, 255, 0.01),
      0px -115px 1px rgba(255, 255, 255, 0.01),
      0px -116px 1px rgba(255, 255, 255, 0.01),
      0px -117px 1px rgba(255, 255, 255, 0.01),
      0px -118px 1px rgba(255, 255, 255, 0.005),
      0px -119px 1px rgba(255, 255, 255, 0.005),
      0px -120px 1px rgba(255, 255, 255, 0.005),
      0px -121px 1px rgba(255, 255, 255, 0.005),
      0px -122px 1px rgba(255, 255, 255, 0.005),
      0px -123px 1px rgba(255, 255, 255, 0.005),
      0px -124px 1px rgba(255, 255, 255, 0.005),
      0px -125px 1px rgba(255, 255, 255, 0.005),
      0px -126px 1px rgba(255, 255, 255, 0.005),
      0px -127px 1px rgba(255, 255, 255, 0.005),
      0px -128px 1px rgba(255, 255, 255, 0.005),
      0px -129px 1px rgba(255, 255, 255, 0.005),
      0px -130px 1px rgba(255, 255, 255, 0.005);
  }
  .LinearImg:hover::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 1);
    height: 30px;
    width: 30px;
    opacity: 1;
    border-radius: 50%;
    transform-style: preserve-3d;
    transform: translateX(-50%) rotate3d(14, 0, 0, 70deg);
    opacity: 0.1;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    > div {
      transform: none !important;
      position: initial !important;
      top: unset !important;
      bottom: unset !important;
      left: unset !important;
      right: unset !important;
      max-width: none !important;
      width: 33%;
      margin-bottom: 30px;
    }
    .LinearImg::before {
      box-shadow: 0px 48px 26px 0px rgba(255, 255, 255, 0.35),
        inset 0px 0px 20px 5px rgba(8, 18, 117, 0.2),
        0px -1px 1px rgba(255, 255, 255, 0.02),
        0px -2px 1px rgba(255, 255, 255, 0.02),
        0px -3px 1px rgba(255, 255, 255, 0.02),
        0px -4px 1px rgba(255, 255, 255, 0.02),
        0px -5px 1px rgba(255, 255, 255, 0.02),
        0px -6px 1px rgba(255, 255, 255, 0.02),
        0px -7px 1px rgba(255, 255, 255, 0.02),
        0px -8px 1px rgba(255, 255, 255, 0.02),
        0px -9px 1px rgba(255, 255, 255, 0.02),
        0px -10px 1px rgba(255, 255, 255, 0.02),
        0px -11px 1px rgba(255, 255, 255, 0.02),
        0px -12px 1px rgba(255, 255, 255, 0.02),
        0px -13px 1px rgba(255, 255, 255, 0.02),
        0px -14px 1px rgba(255, 255, 255, 0.02),
        0px -15px 1px rgba(255, 255, 255, 0.02),
        0px -16px 1px rgba(255, 255, 255, 0.02),
        0px -17px 1px rgba(255, 255, 255, 0.02),
        0px -18px 1px rgba(255, 255, 255, 0.02),
        0px -19px 1px rgba(255, 255, 255, 0.02),
        0px -20px 1px rgba(255, 255, 255, 0.02),
        0px -21px 1px rgba(255, 255, 255, 0.02),
        0px -22px 1px rgba(255, 255, 255, 0.02),
        0px -23px 1px rgba(255, 255, 255, 0.02),
        0px -24px 1px rgba(255, 255, 255, 0.02),
        0px -25px 1px rgba(255, 255, 255, 0.02),
        0px -26px 1px rgba(255, 255, 255, 0.02),
        0px -27px 1px rgba(255, 255, 255, 0.02),
        0px -28px 1px rgba(255, 255, 255, 0.015),
        0px -29px 1px rgba(255, 255, 255, 0.015),
        0px -30px 1px rgba(255, 255, 255, 0.015),
        0px -31px 1px rgba(255, 255, 255, 0.015),
        0px -32px 1px rgba(255, 255, 255, 0.015),
        0px -33px 1px rgba(255, 255, 255, 0.015),
        0px -34px 1px rgba(255, 255, 255, 0.015),
        0px -35px 1px rgba(255, 255, 255, 0.015),
        0px -36px 1px rgba(255, 255, 255, 0.015),
        0px -37px 1px rgba(255, 255, 255, 0.015),
        0px -38px 1px rgba(255, 255, 255, 0.015),
        0px -39px 1px rgba(255, 255, 255, 0.015),
        0px -40px 1px rgba(255, 255, 255, 0.015),
        0px -41px 1px rgba(255, 255, 255, 0.015),
        0px -42px 1px rgba(255, 255, 255, 0.015),
        0px -43px 1px rgba(255, 255, 255, 0.015),
        0px -44px 1px rgba(255, 255, 255, 0.015),
        0px -45px 1px rgba(255, 255, 255, 0.015),
        0px -46px 1px rgba(255, 255, 255, 0.015),
        0px -47px 1px rgba(255, 255, 255, 0.015),
        0px -48px 1px rgba(255, 255, 255, 0.015),
        0px -49px 1px rgba(255, 255, 255, 0.015),
        0px -50px 1px rgba(255, 255, 255, 0.015),
        0px -51px 1px rgba(255, 255, 255, 0.015),
        0px -52px 1px rgba(255, 255, 255, 0.015),
        0px -53px 1px rgba(255, 255, 255, 0.015),
        0px -54px 1px rgba(255, 255, 255, 0.015),
        0px -55px 1px rgba(255, 255, 255, 0.01),
        0px -56px 1px rgba(255, 255, 255, 0.01),
        0px -57px 1px rgba(255, 255, 255, 0.01),
        0px -58px 1px rgba(255, 255, 255, 0.01),
        0px -59px 1px rgba(255, 255, 255, 0.01),
        0px -60px 1px rgba(255, 255, 255, 0.01),
        0px -61px 1px rgba(255, 255, 255, 0.01),
        0px -62px 1px rgba(255, 255, 255, 0.01),
        0px -63px 1px rgba(255, 255, 255, 0.01),
        0px -64px 1px rgba(255, 255, 255, 0.01),
        0px -65px 1px rgba(255, 255, 255, 0.01),
        0px -66px 1px rgba(255, 255, 255, 0.01),
        0px -67px 1px rgba(255, 255, 255, 0.01),
        0px -68px 1px rgba(255, 255, 255, 0.01),
        0px -69px 1px rgba(255, 255, 255, 0.01),
        0px -70px 1px rgba(255, 255, 255, 0.01),
        0px -71px 1px rgba(255, 255, 255, 0.01),
        0px -72px 1px rgba(255, 255, 255, 0.01),
        0px -73px 1px rgba(255, 255, 255, 0.01),
        0px -74px 1px rgba(255, 255, 255, 0.01),
        0px -75px 1px rgba(255, 255, 255, 0.01),
        0px -76px 1px rgba(255, 255, 255, 0.01),
        0px -77px 1px rgba(255, 255, 255, 0.01),
        0px -78px 1px rgba(255, 255, 255, 0.01),
        0px -79px 1px rgba(255, 255, 255, 0.01),
        0px -80px 1px rgba(255, 255, 255, 0.01),
        0px -81px 1px rgba(255, 255, 255, 0.01),
        0px -82px 1px rgba(255, 255, 255, 0.01),
        0px -83px 1px rgba(255, 255, 255, 0.01),
        0px -84px 1px rgba(255, 255, 255, 0.01),
        0px -85px 1px rgba(255, 255, 255, 0.01),
        0px -86px 1px rgba(255, 255, 255, 0.01),
        0px -87px 1px rgba(255, 255, 255, 0.01),
        0px -88px 1px rgba(255, 255, 255, 0.01),
        0px -89px 1px rgba(255, 255, 255, 0.01),
        0px -90px 1px rgba(255, 255, 255, 0.01),
        0px -91px 1px rgba(255, 255, 255, 0.01),
        0px -92px 1px rgba(255, 255, 255, 0.01),
        0px -93px 1px rgba(255, 255, 255, 0.01),
        0px -94px 1px rgba(255, 255, 255, 0.01),
        0px -95px 1px rgba(255, 255, 255, 0.01),
        0px -96px 1px rgba(255, 255, 255, 0.01),
        0px -97px 1px rgba(255, 255, 255, 0.01),
        0px -98px 1px rgba(255, 255, 255, 0.01),
        0px -99px 1px rgba(255, 255, 255, 0.01),
        0px -100px 1px rgba(255, 255, 255, 0.01),
        0px -101px 1px rgba(255, 255, 255, 0.01),
        0px -102px 1px rgba(255, 255, 255, 0.01),
        0px -103px 1px rgba(255, 255, 255, 0.01),
        0px -104px 1px rgba(255, 255, 255, 0.01),
        0px -105px 1px rgba(255, 255, 255, 0.01),
        0px -106px 1px rgba(255, 255, 255, 0.01),
        0px -107px 1px rgba(255, 255, 255, 0.01),
        0px -108px 1px rgba(255, 255, 255, 0.01),
        0px -109px 1px rgba(255, 255, 255, 0.01),
        0px -110px 1px rgba(255, 255, 255, 0.01),
        0px -111px 1px rgba(255, 255, 255, 0.01),
        0px -112px 1px rgba(255, 255, 255, 0.01),
        0px -113px 1px rgba(255, 255, 255, 0.01),
        0px -114px 1px rgba(255, 255, 255, 0.01),
        0px -115px 1px rgba(255, 255, 255, 0.01),
        0px -116px 1px rgba(255, 255, 255, 0.01),
        0px -117px 1px rgba(255, 255, 255, 0.01),
        0px -118px 1px rgba(255, 255, 255, 0.005),
        0px -119px 1px rgba(255, 255, 255, 0.005),
        0px -120px 1px rgba(255, 255, 255, 0.005),
        0px -121px 1px rgba(255, 255, 255, 0.005),
        0px -122px 1px rgba(255, 255, 255, 0.005),
        0px -123px 1px rgba(255, 255, 255, 0.005),
        0px -124px 1px rgba(255, 255, 255, 0.005),
        0px -125px 1px rgba(255, 255, 255, 0.005),
        0px -126px 1px rgba(255, 255, 255, 0.005),
        0px -127px 1px rgba(255, 255, 255, 0.005),
        0px -128px 1px rgba(255, 255, 255, 0.005),
        0px -129px 1px rgba(255, 255, 255, 0.005),
        0px -130px 1px rgba(255, 255, 255, 0.005);
    }
  }
  @media only screen and (max-width: 575px) {
    > div {
      width: 100%;
    }
  }
`;

export default Whatifhover;
