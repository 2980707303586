import styled from 'styled-components';

const PopupWrapper = styled.div`
  .floatingpopup {
    width: 380px;
    position: fixed;
    top: 50%;
    left: 0px;
    background: linear-gradient(
      90deg,
      rgba(0, 14, 59, 1) 36%,
      rgba(0, 35, 99, 1) 100%
    );
    transform: translateY(-50%);
    z-index: 999999;
    img {
      width: 100%;
    }
  }
  .popupcloseicon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
  .floatingpopup.popup_open {
    padding: 30px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
  }
  .floatingpopup.popup_close {
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    .popupcloseicon {
      display: none;
    }
  }
  .myfont {
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    letter-spacing: 0.015em;
    color: #fff;
    margin-bottom: 0px;
    padding-top: 0px;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    .floatingpopup {
      width: 100%;
    }
  }
`;
export default PopupWrapper;
