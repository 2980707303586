import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
// import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
// import Button from 'common/src/components/Button';
// import Card from 'common/src/components/Card';
// import Image from 'common/src/components/Image';
// import Container from 'common/src/components/UI/Container';
import ComboWrapper from './ComboWrapper';
// import RAPFlow from '../../Hosting/Linear';
// import RAPBot from '../../Hosting/InfoHome';
// import { Link } from 'gatsby';
import Snakeborder from '../../Hosting/Snakeborder/Snakeborder';
import RAPFlowImage from 'common/src/assets/image/hosting/Icons/Home/RAPFLOWv3.svg';
import RAPBotImage from 'common/src/assets/image/hosting/Icons/Home/rapbotimage.svg';

const Combination = ({
  cols,
  mainrow,
  row,
  textArea,
  subtitle1,
  title1,
  description,
  button,
}) => {
  return (
    <ComboWrapper>
      <Box {...mainrow}>
        <Box {...row}>
          <Fade bottom cascade>
            <Heading {...title1} content="The Platform" />
          </Fade>
        </Box>
        <Box {...row}>
          <Box {...cols}>
            {/* <Box {...textArea}>
            <Fade bottom cascade>
              <Heading
                {...subtitle1}
                content="RAPBot"
                className="text_center"
              />
              <Heading
                {...title1}
                content="End-to-end automation with intelligent RPA"
                className="text_center"
              />
              <Box>Image goes here</Box>
              <Text
                {...description}
                className="text_center"
                content="Mimic a range of human actions and accelerate workflows with our intelligent RPA engine"
              />
              <Box className="btn learnMore text_center" {...button}>
                <Link to="/platform/rapbot/">
                  <span className="learnMore_txt">Learn More </span>
                  <i className="fa fa-long-arrow-right" />
                </Link>
              </Box>
            </Fade>
          </Box> */}
            <Snakeborder
              content_title="RAPFlow"
              content_subtitle="Full Lifecycle AI Orchestration"
              content_description="Content intelligence platform, build an AI solution in days or weeks not months"
              content_goto="/platform/rapflow/"
              content_image={RAPFlowImage}
            />
          </Box>
          <Box {...cols}>
            {/* <Box {...textArea}>
            <Fade bottom cascade>
              <Heading
                {...subtitle1}
                content="RAPFlow"
                className="text_center"
              />
              <Heading
                {...title1}
                content="Full Lifecycle AI Orchestration"
                className="text_center"
              />
              <Box>Image goes here</Box>
              <Text
                {...description}
                className="text_center"
                content="Build an AI solution in days or weeks not months with our next-gen content intelligence platform"
              />
              <Box className="btn learnMore text_center" {...button}>
                <Link to="/platform/rapflow/">
                  <span className="learnMore_txt">Learn More </span>
                  <i className="fa fa-long-arrow-right" />
                </Link>
              </Box>
            </Fade>
          </Box> */}

            <Snakeborder
              content_title="RAPBot"
              content_subtitle="End-to-end automation with intelligent RPA"
              content_description="Intelligent RPA engine to mimic a range of human actions and accelerate workflows"
              content_goto="/platform/rapbot/"
              content_image={RAPBotImage}
            />
          </Box>
        </Box>
      </Box>
    </ComboWrapper>
  );
};

Combination.propTypes = {
  mainrow: PropTypes.object,
  row: PropTypes.object,
};

Combination.defaultProps = {
  mainrow: {
    width: '100%',
    maxWidth: '1170px',
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'start',
    // pt: "25px",
  },
  row: {
    width: '100%',
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cols: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    mt: ['20px', '20px', '0px', '0px', '0px'],
  },
  textArea: {
    width: ['95%', '95%', '85%', '85%', '85%'],
    height: '100%',
    margin: '0 auto',
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  subtitle1: {
    as: 'h2',
    fontSize: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '600',
    // color: "#0f2137",
    color: '#fff',
    mt: ['10px', '15px', '20px', '20px', '20px'],
    mb: ['10px', '15px', '10px', '10px', '10px'],
    textAlign: 'center',
  },
  title1: {
    as: 'h1',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    color: '#0f2137',
    // color: "#fff",
    letterSpacing: '-0.025em',
    mt: 0,
    mb: ['10px', '10px', '30px', '30px', '30px'],
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    // color: "#252525",
    color: '#fff',
    lineHeight: '1.5',
    mt: ['10px', '15px', '15px', '20px', '20px'],
    mb: ['10px', '15px', '15px', '20px', '20px'],
  },
  button: {
    mt: ['20px', '20px', '20px', '40px', '40px'],
    // mt: ['40px', '40px', '40px', '100px', '100px'],
  },
};
export default Combination;
