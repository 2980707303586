import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import Achievementimage from 'common/src/assets/image/hosting/news/popup_analytics.jpg';
import Achievementimage2 from 'common/src/assets/image/hosting/news/footer_cioreview.jpg';
import Hyperautomation from 'common/src/assets/image/hosting/news/Hyperautomationsmall.png';
import PopupWrapper from './PopupWrapper';
import { Link } from 'gatsby';

const Popup = ({ row, col, title, textArea, description, buttonbox }) => {
  const [cname, setCname] = useState('floatingpopup popup_close');
  const [isopen, setIsopen] = useState('notopen');
  let opened = false;
  const scrollhandler = () => {
    if (opened === false) {
      setCname('floatingpopup popup_open');
      opened = true;
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      scrollhandler();
    });
    return window.removeEventListener('scroll', () => {});
  }, []);

  return (
    <PopupWrapper>
      <Box className={cname}>
        <Text {...title} content="we've got big news to share!" />
        <br />

        <Box>
          <a
            href="/news/RAP-Secures-Pre-Series-A-Funding-From-Artha99"
            target="_blank"
            rel="noopener"
          >
            <img
              {...title}
              src={Hyperautomation}
              style={{
                width: '100px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              alt="RAP secures Pre-Series A funding"
            />
          </a>
          <i
            className="fa fa-times-circle fa-2x popupcloseicon"
            style={{ color: 'white' }}
            onClick={() => {
              setCname('floatingpopup popup_close');
            }}
          />
          {/* <Text
          {...description}
          content="Recognized by The Analytics Insight."
        /> */}
          <p className="myfont " style={{ float: 'center' }}>
            RAP secured pre-Series A funding
          </p>
          <Box {...buttonbox}>
            {/* <Link to="/miscellaneous/checklist-for-AI-automation">
                <Text {...adtext} content={"Click here to checkout"} />
              </Link> */}
            {/* <Link
            to="/news/analytics-insights-magazine"
            className="btn btn_tertiary"
          >
            <span className="btn-text">Know More</span>
          </Link> */}
          </Box>
        </Box>
        {/* //////////----Column 2------//////// */}
      </Box>
    </PopupWrapper>
  );
};

Popup.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  textArea: PropTypes.object,
  description: PropTypes.object,
};

Popup.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    // justifyContent: 'flex-start',
    ml: '-15px',
    mr: '-15px',
  },
  textArea: {
    margin: '20px 15px',
    padding: '30px 15px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    className: 'misc_contianer',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '5px',
    pt: '15px',
    textAlign: 'center',
  },
  description: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '0.015em',
    color: '#fff',
    mb: '0px',
    pt: '15px',
    textAlign: 'center',
  },
  title: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '0.015em',
    color: '#fff',
    mb: '0px',
    pt: '15px',
    textAlign: 'center',
  },
  buttonbox: {
    mt: '15px',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  col: {
    width: [1, 1, 0.5, 0.5, 0.5],
    flexBox: true,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['10px', '10px', '10px', '10px', '10px'],
    mt: ['0px', '0px', '20px', '20px', '20px'],
  },
};

export default Popup;
