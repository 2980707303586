import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Fade from 'react-reveal/Fade';
import Container from 'common/src/components/UI/Container';

import SnakeWrapper from './SnakeWrapper';
import { Link } from 'gatsby';

// import './bannerother.css';

const Snakeborder = ({
  row,
  subtitle1,
  title1,
  description,
  card,
  contentholder,
  imageholder,
  button,
  ...props
}) => {
  return (
    <>
      <SnakeWrapper>
        <Box {...row}>
          <Box {...card} className="card">
            {/* <Box {...card} className="pinkcard"> */}
            <span className="runner runner1"></span>
            <span className="runner runner2"></span>
            <span className="runner runner3"></span>
            <span className="runner runner4"></span>

            <Box {...contentholder} className="content">
              <Fade bottom cascade>
                <Heading
                  {...title1}
                  content={props.content_title}
                  className="text_center title"
                />
                <Heading
                  {...subtitle1}
                  content={props.content_subtitle}
                  className="text_center subtitle"
                />
              </Fade>
              <Box {...imageholder}>
                <img
                  src={props.content_image}
                  className="platfromimage"
                  alt={props.content_title}
                ></img>
              </Box>
              <Fade bottom cascade>
                <Text
                  {...description}
                  className="text_center description"
                  content={props.content_description}
                />
                <Box className="btn learnMore text_center" {...button}>
                  <Link to={props.content_goto}>
                    <span style={{ display: 'none' }}>
                      {props.content_title}
                    </span>
                    <span className="learnMore_txt">Learn More </span>
                    <i className="fa fa-long-arrow-right" />
                  </Link>
                </Box>
                {/* <h2>{props.content_title}</h2>
              <h3>{props.content_title}</h3>
              <p>{props.content_title}</p> */}
              </Fade>
            </Box>
          </Box>
        </Box>
      </SnakeWrapper>
    </>
  );
};

Snakeborder.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  subdescription: PropTypes.object,
  button: PropTypes.object,
  card: PropTypes.object,
  contentholder: PropTypes.object,
  imageholder: PropTypes.object,
};

Snakeborder.defaultProps = {
  mainrow: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: [1, '100%', '100%', '100%', '100%'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    // width: [1, "100%", "50%", "50%", "50%"],
    width: '100%',
    className: 'container',
  },
  card: {
    ml: '30px',
    mr: '30px',
  },
  contentholder: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    p: '10px',
  },
  imageholder: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    width: '150px',
    flexBox: true,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle1: {
    as: 'h2',
    fontSize: ['20px', '20px', '20px', '20px', '20px'],
    fontWeight: '600',
    color: '#0f2137',
    // color: "#fff",
    // mt: ["10px", "10px", "10px", "10px", "10px"],
    mb: ['10px', '10px', '10px', '10px', '10px'],
    textAlign: 'center',
  },
  title1: {
    as: 'h1',
    fontSize: ['28px', '28px', '30px', '30px', '30px'],
    fontWeight: '600',
    color: '#0f2137',
    // color: "#fff",
    letterSpacing: '-0.025em',
    mt: 0,
    mb: ['5px', '5px', '5px', '5px', '5px'],
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    // color: "#fff",
    lineHeight: '1.3',
    mt: ['10px', '15px', '15px', '20px', '20px'],
    mb: ['10px', '15px', '15px', '20px', '20px'],
  },
  button: {
    // mt: ["20px", "20px", "20px", "20px", "20px"],
    // mt: ['40px', '40px', '40px', '100px', '100px'],
  },
};

export default Snakeborder;
